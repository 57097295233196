.App {
  display: flex;
  flex-direction: column;
  background-color: #F4F4F4;
  padding-bottom: 150px;
}
.App-header {
  display: flex;
  flex-direction: row;
  padding: 16.44px  16.44px 6.57px  16.44px;
  background-color: #F4F4F4;
}
.App-header-dot {
  display: flex;
  color: #ff0000;
  font-size: 13.152px; 
  margin-right: 4px;
}
.App-header-text {
  display: flex;
  color: #333;
  font-size: 13.152px; 
}
.App-item1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 55px;
  padding-left:  16.44px;
  border-bottom-color: #EEEEEE;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  background-color: white;
}
.App-item1-checkicon {
  display: flex;
  width: 25.91px;
  height: 25.91px;
  margin-right: 6px;
}
.App-item1-checktext {
  display: flex;
  color: #333;
  font-size: 16.44px; 
}
.App-item2 {
  display: flex;
  background-color: white;
  height: 150px;
  padding: 16.44px;
  font-size: 16px; 
  border: none;
  text-align: start;
  outline: none;
  background-color: white;
}
.App-item3 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: white;
  padding-bottom: 16.44px;
}
.App-item3-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98.63px;
  height: 98.63px;
  border-color: #979797;
  border-width: 1px;
  border-style: dashed;
  margin: 16.44px 0px 0px 16.44px;
} 
.App-item3-view-icon {
  display: flex;
  width: 24.11px;
  height: 24.11px;
}
.App-item3-view-text {
  display: flex;
  color: #666;
  font-size: 13.15px;
  margin-top: 6.576px;
}
.App-item3-pic {
  display: flex;
  width: 98.63px;
  height: 98.63px;
  margin: 16.44px 0px 0px 16.44px;
}
.App-toast {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.App-toast-text {
  display: flex;
  background-color: #707071;
  padding: 16.44px 20px;
  color: white;
  border-radius: 10px;
}
.App-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #57BD69;
  margin-left: 10%;
  margin-top: 10%;
  width: 80%;
  height: 45px;
  border-radius: 22.5px;
  color: white;
  font-size: 15px;
}
.App-success-modal {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F4F4F4;
}
.App-success-modal-icon {
  display: flex;
  width: 49px;
  height: 49px;
  margin: 37px 0px;
}
.App-success-modal-tip {
  display: flex;
  color: #333;
  font-size: 19px;
  margin-bottom: 33px;
}
.App-success-modal-content {
  display: flex;
  color: #333;
  font-size: 15px;
  margin-bottom: 52px;
}
.App-success-modal-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 35px;
  color: white;
  font-size: 15px;
  background-color: #57BD69;
}
